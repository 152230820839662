import React from "react"
import Img from "gatsby-image"
import "./styles.scss"

const HalfContentWithImage = ({ title, content, image, contentLeft }) => {
  return (
    <section
      className={`half-content-half-image ${contentLeft ? "content-left" : ""}`}
    >
      <div className="half-content-half-image__content-container">
        <h2 className="half-content-half-image__title">{title.text}</h2>
        <div className="half-content-half-image__content">
          <div
            className="half-content-half-image__content--inner"
            dangerouslySetInnerHTML={{ __html: content.html }}
          ></div>
        </div>
      </div>
      <div className="half-content-half-image__image-container">
        <Img className="half-content-half-image__image" fluid={image.fluid} />
      </div>
    </section>
  )
}

export default HalfContentWithImage
