import HalfContentHalfImage from "../components/HalfContentHalfImage"
import Layout from "../components/Layout"
import React from "react"
import SEO from "../components/seo"
import { graphql } from "gatsby"

const FleetPage = ({ data }) => {
  const content = data.allPrismicFleetPage.edges[0].node.data.content_section
  return (
    <Layout>
      <SEO title="Fleet" />
      <div
        className="fleet-page"
        style={{ paddingTop: 58, backgroundColor: "#484848" }}
      >
        {content.map((section, i) => {
          return (
            <HalfContentHalfImage
              key={i}
              title={section.title}
              content={section.content}
              image={section.image}
              contentLeft={i % 2}
            />
          )
        })}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query FleetPageQuery {
    allPrismicFleetPage {
      edges {
        node {
          data {
            content_section {
              content {
                html
              }
              image {
                fluid(maxWidth: 1000) {
                  src
                  srcWebp
                  srcSetWebp
                  srcSet
                  sizes
                  base64
                  aspectRatio
                }
              }
              title {
                text
              }
            }
          }
        }
      }
    }
  }
`
export default FleetPage
